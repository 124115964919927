import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import BlockContent from '@sanity/block-content-to-react';
import SimpleReactLightbox from 'simple-react-lightbox';
import objectFitImages from 'object-fit-images';
import '../translation/i18n';

import serializers from '../utils/serializers';

import Watermark from '../assets/Elementos-SITE-CleverREAL-05.png';

import SEO from '../components/seo';
import Menu from '../components/Menu';
import VerticalPanel from '../components/VerticalPanel';
import ImageSlide from '../components/ImageSlide';
import TextSlide from '../components/TextSlide';
import WhoWeAre from '../components/WhoWeAre';
import Team from '../components/Team';
import Services from '../components/Services';
import PreviousProjects from '../components/PreviousProjects';
import Portfolio from '../components/Portfolio';
import Contacts from '../components/Contacts';
import Footer from '../components/Footer';

// import variables from '../styles/variables.scss';
import './index.scss';
import './tablet.scss';
import './mobile.scss';

import '../fragments.ts';
import { renderTitle } from '../utils/functions';
import { DEFAULT_LANG } from '../constants';
import { localize } from '../utils/functions';

interface IndexPageProps {
  lang?: string;
}

const IndexPage = ({ lang = 'pt' }: IndexPageProps) => {
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    __main(jQuery);
    objectFitImages();
  }, []);
  const data = useStaticQuery(graphql`
    {
      sanitySite {
        logos {
          verticalImage {
            asset {
              url
            }
          }
          horizontalImage {
            asset {
              url
            }
          }
          horizontalWithSloganImage {
            asset {
              url
            }
          }
        }
        menu {
          home {
            ...LocaleStringFragment
          }
          whoWeAre {
            ...LocaleStringFragment
          }
          ourServices {
            ...LocaleStringFragment
          }
          previousProjects {
            ...LocaleStringFragment
          }
          portfolio {
            ...LocaleStringFragment
          }
          contacts {
            ...LocaleStringFragment
          }
        }
        homeSection {
          slogan {
            ...LocaleStringFragment
          }
          socialMedia {
            facebookUrl
            linkedInUrl
          }
          slides {
            ... on SanityTextSlide {
              text {
                ...LocaleTextFragment
              }
              background {
                asset {
                  url
                }
              }
            }
            ... on SanityImageSlide {
              image {
                asset {
                  url
                }
              }
              background {
                asset {
                  url
                }
              }
            }
          }
        }
        whoWeAreSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          introText {
            ...LocaleBasicPortableTextFragment
          }
          missionTitle {
            ...LocaleStringFragment
          }
          missionText {
            ...LocaleBasicPortableTextFragment
          }
          valuesTitle {
            ...LocaleStringFragment
          }
          values {
            ...LocaleStringFragment
          }
          teamTitle {
            ...LocaleStringFragment
          }
          teamIntroText {
            ...LocaleBasicPortableTextFragment
          }
          teamLeaders {
            profileImage {
              asset {
                url
              }
            }
            name
            department {
              ...LocaleStringFragment
            }
            linkedInUrl
            curriculumVitae {
              asset {
                url
              }
            }
          }
          teamWorkers {
            profileImage {
              asset {
                url
              }
            }
            name
            department {
              ...LocaleStringFragment
            }
            linkedInUrl
            curriculumVitae {
              asset {
                url
              }
            }
          }
        }
        _rawWhoWeAreSection
        ourServicesSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          intro {
            ...LocaleTextFragment
          }
          services {
            image {
              asset {
                url
              }
            }
            name {
              ...LocaleStringFragment
            }
            description {
              ...LocaleTextFragment
            }
          }
        }
        previousProjectsSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          projects {
            year
            name {
              ...LocaleStringFragment
            }
            description {
              ...LocaleDescriptionPortableTextFragment
            }
            image {
              asset {
                url
              }
            }
          }
        }
        portfolioSection {
          sectionTitle {
            ...LocaleStringFragment
          }

          inDevelopmentActives {
            _key
            image {
              asset {
                url
              }
            }
            topLineTitle {
              ...LocaleStringFragment
            }
            bottomLineTitle {
              ...LocaleStringFragment
            }
            description {
              ...LocaleDescriptionPortableTextFragment
            }
          }
          inCommercializationActives {
            _key
            imageGallery {
              asset {
                url
              }
            }
            logo {
              asset {
                url
              }
            }
            description {
              ...LocaleDescriptionPortableTextFragment
            }
            features {
              _key
              featureIcon {
                asset {
                  url
                }
              }
              featureDescription {
                ...LocaleDescriptionPortableTextFragment
              }
            }
            url {
              ...LocaleUrlFragment
            }
          }
        }

        contactsSection {
          sectionTitle {
            ...LocaleStringFragment
          }
          address
          phone
          email
          linkedInUrl
        }
      }
    }
  `);

  const { i18n } = useTranslation();
  if (i18n.language !== lang) {
    i18n.changeLanguage(lang);
  }

  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }
  const {
    logos,
    homeSection,
    menu,
    whoWeAreSection,
    ourServicesSection,
    portfolioSection,
    previousProjectsSection,
    contactsSection,
  } = localize(data.sanitySite, langs);

  const navigationBar = [
    { anchor: 'home', name: menu.home },
    { anchor: 'whoWeAre', name: menu.whoWeAre },
    { anchor: 'ourServices', name: menu.ourServices },
    { anchor: 'previousProjects', name: menu.previousProjects },
    { anchor: 'portfolio', name: menu.portfolio },
    { anchor: 'contacts', name: menu.contacts },
  ];

  return (
    <SimpleReactLightbox>
      {' '}
      <div>
        <SEO />
        <header id="top" className="header-home">
          <div className="brand-panel">
            <img className="brand-panel-logo" src={logos.verticalImage.asset.url} alt="" />
            <div className="water-mark-wrapper">
              <img className="water-mark" src={Watermark} alt="" />
            </div>
          </div>
          <div className="vertical-panel" />
          <VerticalPanel
            slogan={homeSection.slogan}
            facebookUrl={homeSection.socialMedia && homeSection.socialMedia.facebookUrl}
            linkedInUrl={homeSection.socialMedia && homeSection.socialMedia.linkedInUrl}
          ></VerticalPanel>

          <Menu
            horizontalLogo={logos.horizontalImage.asset.url}
            navigationBar={navigationBar}
            lang={lang}
          ></Menu>
        </header>
        {/* Layout */}
        <div className="layout">
          {/* Home */}
          <div id="home" className="main">
            <div className="arrow-left" />
            <div className="arrow-right" />
            <div className="rev_slider_wrapper">
              <div id="rev_slider" className="rev_slider fullscreenbanner">
                <ul>
                  {homeSection.slides.map((slide: any, i: number) =>
                    slide.hasOwnProperty('image') ? (
                      <ImageSlide
                        key={i}
                        backgroundUrl={slide.background.asset.url}
                        imageUrl={slide.image.asset.url}
                      ></ImageSlide>
                    ) : (
                      <TextSlide
                        key={i}
                        backgroundUrl={slide.background.asset.url}
                        text={slide.text}
                      ></TextSlide>
                    ),
                  )}
                </ul>
              </div>
            </div>
          </div>

          <WhoWeAre
            watermark={whoWeAreSection.sectionTitle}
            sectionTitle={renderTitle(whoWeAreSection.sectionTitle)}
            textIntro={
              <BlockContent blocks={whoWeAreSection.introText} serializers={serializers} />
            }
            missionTitle={whoWeAreSection.missionTitle}
            missionText={
              <BlockContent blocks={whoWeAreSection.missionText} serializers={serializers} />
            }
            valuesTitle={whoWeAreSection.valuesTitle}
            values={whoWeAreSection.values}
          ></WhoWeAre>

          <Team
            watermark={whoWeAreSection.teamTitle}
            title={whoWeAreSection.teamTitle}
            teamText={
              <BlockContent blocks={whoWeAreSection.teamIntroText} serializers={serializers} />
            }
            teamLeaders={whoWeAreSection.teamLeaders}
            teamWorkers={whoWeAreSection.teamWorkers}
          ></Team>

          <Services
            title={renderTitle(ourServicesSection.sectionTitle)}
            introText={ourServicesSection.intro}
            services={ourServicesSection.services}
          ></Services>

          <PreviousProjects
            sectionTitle={renderTitle(previousProjectsSection.sectionTitle)}
            projects={previousProjectsSection.projects.map((project: any) => ({
              ...project,
              description: <BlockContent blocks={project.description} serializers={serializers} />,
            }))}
          ></PreviousProjects>

          <Portfolio
            title={renderTitle(portfolioSection.sectionTitle)}
            inDevelopmentActives={portfolioSection.inDevelopmentActives}
            inCommercializationActives={portfolioSection.inCommercializationActives}
            // actives={portfolioSection.actives}
          ></Portfolio>

          <Contacts
            title={renderTitle(contactsSection.sectionTitle)}
            address={contactsSection.address}
            contactPhone={contactsSection.phone}
            contactEmail={contactsSection.email}
            linkedInUrl={contactsSection.linkedInUrl}
          ></Contacts>

          <Footer horizontalLogoSloganUrl={logos.horizontalWithSloganImage.asset.url}></Footer>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default IndexPage;
