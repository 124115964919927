import React from 'react';

interface MenuTopicProps {
  anchor: string;
  name: string;
  className?: string;
}

export default function MenuTopic({ anchor, name, className }: MenuTopicProps) {
  return (
    <li>
      <a className={className}
        href={'#' + anchor + '-anchor'}
        // @ts-ignore
        onClick={() => $('.navbar-collapse').collapse('hide')}
      >
        {name}
      </a>
    </li>
  );
}
