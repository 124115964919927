import React, { useEffect, useState } from 'react';

// DO COMPONENT
// export function ProjectCard(props: any) {
//   const { project } = props;
//   return (
//     <div className="project project-light">
//       <a
//         href={project.backgroundImage.asset.url}
//         title={project.name}
//         data-text={project.description}
//       >
//         <p>{project.description}</p>
//         <figure>
//           <img className="projects-img" alt="" src={project.backgroundImage.asset.url} />
//           <figcaption>
//             <h3 className="project-title">{project.groupName}</h3>
//             <span className="project-location">{project.name}</span>
//             <h4 className="project-category">{project.type}</h4>
//             <div className="project-zoom" />
//           </figcaption>
//         </figure>
//         <p>{project.description}</p>
//       </a>
//     </div>
//   );
// }

export function renderTitle(title: string): React.ReactNode {
  const titleWords = title.trim().split(' ');
  const lastTitleWord = titleWords[titleWords.length - 1];
  const allTitleWordsButTheLast = titleWords.slice(0, titleWords.length - 1).join(' ');
  return (
    <>
      {allTitleWordsButTheLast} <span>{lastTitleWord}</span>
    </>
  );
}

export function getAnchor() {
  const currentUrl = document.URL;
  const urlParts = currentUrl.split('#');

  return urlParts.length > 1 ? urlParts[1] : null;
}

export function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function withLineBreaks(text: string) {
  const array = [];
  for (const textPart of text.split('\n')) {
    array.push(textPart);
    array.push(<br key={Math.random()} />);
  }
  return array.slice(0, -1);
}

export function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function langFieldToRaw(langField: string) {
  return '_raw' + langField[0].toUpperCase() + langField.substr(1);
}
export function localize(value: any, languages: Array<string>): any {
  if (Array.isArray(value)) {
    return value.map(v => localize(v, languages));
  } else if (value !== null && typeof value === 'object') {
    if (/^locale_/.test(value._type)) {
      const field = languages.find(lang => value[lang]);
      if (field && value[field]) {
        return value[field];
      }
      const foundField = languages.find(lang => value[langFieldToRaw(lang)]);
      const rawField = foundField && langFieldToRaw(foundField);
      if (rawField && value[rawField]) {
        return value[rawField];
      }
      return undefined;
    }
    return Object.keys(value).reduce((result: any, key: string) => {
      result[key] = localize(value[key], languages);
      return result;
    }, {});
  }
  return value;
}
