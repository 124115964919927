import React, { useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Trans } from 'react-i18next';

import { FaPlus } from 'react-icons/fa';

import serializers from '../utils/serializers';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';
import { SanityImage } from '../types';

interface PortfolioProps {
  title: React.ReactNode;
  inDevelopmentActives: Array<{
    _key: string;
    topLineTitle: string;
    bottomLineTitle: string;
    description: React.ReactNode;
    url: string;
    image: SanityImage;
    logo: SanityImage;
  }>;
  inCommercializationActives: Array<{
    _key: string;
    imageGallery: Array<SanityImage>;
    logo: SanityImage;
    description: string;
    features: Array<{
      featureIcon: SanityImage;
      featureDescription: string;
    }>;
    url: string;
  }>;
}

const Portfolio = ({ title, inDevelopmentActives, inCommercializationActives }: PortfolioProps) => {
  const allInCommercialization = inDevelopmentActives.length === 0;
  const allInDevelopment = inCommercializationActives.length === 0;
  const [activesShown, setActivesShown] = useState(
    allInCommercialization ? 'inCommercialization' : 'inDevelopment',
  );
  const [exitingState, setExitingState] = useState('none');
  const [inCommercializationOpenStates, setInCommercializationOpenStates] = useState<
    Array<boolean>
  >(new Array(inCommercializationActives.length).fill(false));
  const [selectedImageIndexes, setSelectedImageIndexes] = useState<Array<number>>(
    new Array(inCommercializationActives.length).fill(0),
  );

  function makeEnterAnimation() {
    setExitingState('swapping');
    setTimeout(() => {
      setExitingState('entering');
      setTimeout(() => {
        setExitingState('none');
      }, 1000);
    }, 0);
  }

  function handleChangeActiveShownClick(newActivesShown: string) {
    setExitingState('exiting');
    setTimeout(() => {
      setActivesShown(newActivesShown);
      makeEnterAnimation();
    }, 1000);
  }

  const renderedPortfolio = (
    <section id="portfolio" className="project-details">
      <div id="portfolio-anchor" className="anchor"></div>
      <header className="section-header">
        <h2 className="section-title">{title}</h2>
        {!allInCommercialization && !allInDevelopment && (
          <div className="section-buttons">
            <button
              className="development-button wow fadeInUp"
              disabled={activesShown === 'inDevelopment' || exitingState !== 'none'}
              onClick={() => {
                handleChangeActiveShownClick('inDevelopment');
              }}
            >
              <Trans i18nKey="portfolio.development_button">
                Em <strong>Desenvolvimento</strong>
              </Trans>
            </button>
            <button
              className="commercialization-button wow fadeInUp"
              disabled={activesShown === 'inCommercialization' || exitingState !== 'none'}
              onClick={() => {
                handleChangeActiveShownClick('inCommercialization');
              }}
            >
              <Trans i18nKey="portfolio.commercialization_button">
                Em <strong>Comercialização</strong>
              </Trans>
            </button>
          </div>
        )}
      </header>
      <div className="container wow fadeInUp">
        {activesShown === 'inDevelopment' &&
          inDevelopmentActives.map((active, i: number) => (
            <div
              key={active._key}
              className={
                'inDevelopment project-details-item'
                // (exitingState ? ' exiting' : '')
              }
              style={{
                visibility: exitingState === 'swapping' ? 'hidden' : undefined,
                display: inCommercializationActives ? undefined : 'none',
              }}
            >
              <div className="row row-image-right">
                <div className="project-details-info-wrapper">
                  <div
                    className={
                      'project-details-info' +
                      (exitingState === 'exiting' || exitingState === 'swapping'
                        ? ' fadeOutLeft animated'
                        : '') +
                      (exitingState === 'entering' ? ' fadeInLeft animated' : '')
                      // (exitingState === 'none' ? ' wow fadeInLeft' : '')
                    }
                    style={{ animationName: exitingState !== 'none' ? undefined : 'none' }}
                  >
                    <h3 className="top-line-title">{active.topLineTitle}</h3>
                    <h3 className="project-details-title">{active.bottomLineTitle}</h3>
                    <BlockContent blocks={active.description} serializers={serializers} />
                    {/* <div className="active-content">
                      <a className="project-url" href={active.url}>
                        {active.url}
                      </a>
                      {active.logo ? (
                        <img
                          alt=""
                          className="active-logo"
                          src={active.logo.asset.url + '?w=240&fit=crop'}
                        />
                      ) : null}
                    </div> */}
                  </div>
                </div>
                <div
                  className={
                    'project-details-img col-md-8 col-md-offset-4' +
                    (exitingState === 'exiting' || exitingState === 'swapping'
                      ? ' fadeOutRight animated'
                      : '') +
                    (exitingState === 'entering' ? ' fadeInRight animated' : '')
                  }
                >
                  {active.image && (
                    <img
                      alt=""
                      className="portfolioImage img-responsive"
                      src={active.image.asset.url + '?w=820&h=1000&fit=crop'}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}

        {activesShown === 'inCommercialization' &&
          inCommercializationActives.map((active, activeIndex: number) => (
            <div
              key={active._key}
              className="projectInCommercializationContainer project-details-item "
              style={{
                visibility: exitingState === 'swapping' ? 'hidden' : undefined,
                // display:
                //   active.isInCommercialization === (activesShown !== 'inDevelopment')
                //     ? undefined
                //     : 'none',
              }}
            >
              <div
                className={
                  'projectImagesContainer' +
                  (exitingState === 'exiting' || exitingState === 'swapping'
                    ? ' fadeOutLeft animated'
                    : '') +
                  (exitingState === 'entering' ? ' fadeInLeft animated' : '')
                }
              >
                {active.imageGallery && (
                  <div className="displayImageWrapper">
                    <img
                      alt=""
                      className="displayImage img-responsive"
                      src={
                        active.imageGallery[selectedImageIndexes[activeIndex]].asset.url +
                        '?w=570&h=660&fit=crop'
                      }
                    />
                  </div>
                )}
                <div className="thumbnailsContainer">
                  {active.imageGallery.map((thumbnailImage, thumbnailIndex: number) => (
                    <button
                      key={thumbnailIndex}
                      onClick={() => {
                        const newSelectedImageIndexes = [...selectedImageIndexes];
                        newSelectedImageIndexes[activeIndex] = thumbnailIndex;
                        setSelectedImageIndexes(newSelectedImageIndexes);
                      }}
                    >
                      <img
                        alt=""
                        className={
                          'thumbnailImage' +
                          (thumbnailIndex === selectedImageIndexes[activeIndex] ? ' selected' : '')
                        }
                        src={thumbnailImage.asset.url + '?w=820&h=1000&fit=crop'}
                      />
                    </button>
                  ))}
                </div>
              </div>
              <div
                className={
                  'projectInfoContainer' +
                  (inCommercializationOpenStates[activeIndex] ? ' open' : '') +
                  (exitingState === 'exiting' || exitingState === 'swapping'
                    ? ' fadeOutRight animated'
                    : '') +
                  (exitingState === 'entering' ? ' fadeInRight animated' : '')
                  // (exitingState === 'none' ? ' wow fadeInLeft' : '')
                }
                style={{ animationName: exitingState !== 'none' ? undefined : 'none' }}
              >
                <div className="projectDescription">
                  {active.logo ? (
                    <div className="logoWrapper">
                      <img alt="" className="projectLogo" src={active.logo.asset.url} />
                    </div>
                  ) : null}
                  <BlockContent blocks={active.description} serializers={serializers} />
                  <button
                    className={
                      'viewMoreButton' + (inCommercializationOpenStates[activeIndex] ? ' open' : '')
                    }
                    onClick={() => {
                      const newInCommercializationOpenStates = [...inCommercializationOpenStates];
                      newInCommercializationOpenStates[
                        activeIndex
                      ] = !newInCommercializationOpenStates[activeIndex];
                      setInCommercializationOpenStates(newInCommercializationOpenStates);
                    }}
                  >
                    <span className="buttonInner"></span>
                  </button>
                </div>
                {/* FEATURES */}
                <div className="projectFeatures">
                  {active.features.map((feature, i: number) => (
                    <div key={i} className="feature">
                      <div className="featureImage">
                        <img src={feature.featureIcon.asset.url} alt="" />
                      </div>
                      <BlockContent
                        className="featureDescription"
                        blocks={feature.featureDescription}
                        serializers={serializers}
                      />
                    </div>
                  ))}
                  <button className="knowMoreButton">
                    <a target="_blank" rel="noreferrer" className="project-url" href={active.url}>
                      <Trans i18nKey="portfolio.knowMore_button">Saiba Mais</Trans>
                    </a>
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
  return renderedPortfolio;
};

export default Portfolio;
