import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';

interface FooterProps {
  horizontalLogoSloganUrl: string;
}

const Footer = ({ horizontalLogoSloganUrl }: FooterProps) => {
  return (
    <div className="content">
      {/* Footer */}
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row-base row">
            <div className="col-base text-left-md col-md-6">
              <img className="footer-logo" src={horizontalLogoSloganUrl} alt="" />
            </div>
            <div className="col-base text-right-md col-md-6">
              &copy; 2020 | All Rights Reserved | Powered by{' '}
              <a href="https://moscadigital.pt/?utm_source=cleverreal.pt" target="_blank">
                Mosca Digital
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
