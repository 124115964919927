import React from 'react';

const serializers = {
  marks: {
    sup: (props: any) => {
      return <sup>{props.children}</sup>;
    },
    sub: (props: any) => {
      return <sub>{props.children}</sub>;
    },
    link: (props: any) => {
      return (
        <a href={props.mark.href} target="_blank">
          {props.children}
        </a>
      );
    },
  },
};

export default serializers;
