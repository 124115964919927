import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import MenuTopic from '../components/MenuTopic';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';

interface MenuProps {
  horizontalLogo: string;
  navigationBar: Array<{}>;
  lang: string;
}

const Menu = ({ horizontalLogo, navigationBar, lang }: MenuProps) => {
  return (
    <>
      <nav className="navbar-desctop visible-lg">
        <div className="container">
          <a href="#top" className="brand js-target-scroll">
            <img src={horizontalLogo} alt="" />
          </a>
          <ul className="navbar-desctop-menu">
            {navigationBar.map((menuTopic: any, i: number) => (
              <MenuTopic key={i} anchor={menuTopic.anchor} name={menuTopic.name}></MenuTopic>
            ))}
            {lang === 'pt' ? (
              <li className="language-selector">
                <strong>PT | </strong>
                <a href={'/en/'}>EN</a>
              </li>
            ) : (
              <li className="language-selector">
                <a href={'/'}>PT</a>
                <strong> | EN</strong>
              </li>
            )}
          </ul>
        </div>
      </nav>
      <nav className="navbar-mobile">
        <a href="#top" className="brand js-target-scroll">
          <img src={horizontalLogo} alt="" />
        </a>
        {lang === 'pt' ? (
          <span className="language-selector">
            {' '}
            <strong>PT | </strong>
            <a href={'/en/'}>EN</a>
          </span>
        ) : (
          <span className="language-selector">
            {' '}
            <a href={'/'}>PT</a>
            <strong> | EN</strong>
          </span>
        )}
        <button
          type="button"
          className="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#navbar-mobile"
        >
          <span className="sr-only">Abrir e fechar menu</span>
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="navbar-nav-mobile">
            {navigationBar.map(
              (menuTopic: any, i: number) =>
                i === navigationBar.length - 1 ? (
                  <MenuTopic
                    className="lastMenuTopic"
                    key={i}
                    anchor={menuTopic.anchor}
                    name={menuTopic.name}
                  ></MenuTopic>
                ) : (
                  <MenuTopic key={i} anchor={menuTopic.anchor} name={menuTopic.name}></MenuTopic>
                ),
              // <MenuTopic key={i} anchor={menuTopic.anchor} name={menuTopic.name}></MenuTopic>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Menu;
