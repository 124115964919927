import React, { useState } from 'react';
import fetch from 'isomorphic-fetch';
import { encodeFormData } from '../utils/functions';
import { useTranslation } from 'react-i18next';

interface ContactProps {
  title: React.ReactNode;
  address: string;
  contactEmail: string;
  contactPhone: string;
  linkedInUrl: string;
}

const Contacts = ({ title, address, contactPhone, contactEmail, linkedInUrl }: ContactProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [formError, setFormError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const hasErrors = nameError || emailError || phoneError || messageError || formError;

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (submitted || submitting) {
      return;
    }

    setSubmitting(true);

    let nameError = '';
    let emailError = '';
    let phoneError = '';
    let messageError = '';

    if (!name.trim()) {
      nameError = t('contacts.form.required_field_error', 'O campo {{field}} é obrigatório.', {
        field: t('contacts.form.name', 'Nome'),
      });
    }
    if (!email.trim()) {
      emailError = t('contacts.form.required_field_error', 'O campo {{field}} é obrigatório.', {
        field: t('contacts.form.email', 'Email'),
      });
    } else if (!email.match(/^\S+@\S+\.\S+$/)) {
      emailError = t(
        'contacts.form.invalid_email_error',
        'Email inválido (e.g. email@example.com)',
      );
    }
    if (!phone.trim()) {
      phoneError = t('contacts.form.required_field_error', 'O campo {{field}} é obrigatório.', {
        field: t('contacts.form.phone', 'Telefone'),
      });
    }
    // if (!message.trim()) {
    //   messageError = 'Este campo é obrigatório.';
    // }

    setNameError(nameError);
    setEmailError(emailError);
    setPhoneError(phoneError);
    setMessageError(messageError);

    if (!nameError && !emailError && !phoneError && !messageError) {
      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encodeFormData({
            'form-name': 'contact',
            ...{
              name,
              email,
              phone,
              company,
              message,
            },
          }),
        });
        setSubmitted(true);
        setName('');
        setEmail('');
        setPhone('');
        setCompany('');
        setMessage('');
        // if (window.dataLayer) {
        //   window.dataLayer.push({ event: 'form_submitted' });
        // }
      } catch (error) {
        if (error && error.message === 'Failed to fetch') {
          setFormError(
            t(
              'contacts.form.network_error',
              'Ocorreu um erro de rede. Por favor, tente mais tarde.',
            ),
          );
        } else {
          setFormError(
            t(
              'contacts.form.unknown_error',
              'Ocorreu um erro desconhecido. Por favor, tente mais tarde.',
            ),
          );
        }
      }
    }
    setSubmitting(false);
  };

  return (
    <section id="contacts" className="contacts section">
      <div id="contacts-anchor" className="anchor"></div>
      <div className="container">
        <header className="section-header">
          <h2 className="section-title">{title}</h2>
        </header>
        <div className="section-content">
          <div className="row-base row">
            <div className="col-address col-base col-md-4">
              <div>
                <i className="fas fa-map-marker-alt"></i>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={'https://www.google.com/maps/place/' + encodeURI(address)}
                >
                  {address}
                </a>
              </div>
              <div>
                <i className="fas fa-phone-alt"></i>
                <a target="_blank" rel="noreferrer" href={'tel:' + contactPhone}>
                  {contactPhone}
                </a>
              </div>
              <div>
                <i className="fas fa-envelope"></i>
                <a target="_blank" rel="noreferrer" href={'mailto:' + contactEmail}>
                  {contactEmail}
                </a>
              </div>
              <div>
                <i className="fab fa-linkedin-in"></i>
                <a target="_blank" rel="noreferrer" href={linkedInUrl}>
                  LinkedIn
                </a>
              </div>
            </div>

            {/* ERRO NO INPUT*/}

            <div className="col-base  col-md-8">
              <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
                <div className="row-field row">
                  <div className="col-field col-sm-6 col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className={'form-control' + (nameError ? ' error wobble-error' : '')}
                        name="name"
                        placeholder={t('contacts.form.name', 'Nome')}
                        value={name}
                        onChange={event => {
                          // @ts-ignore
                          setName(event.target.value);
                          setNameError('');
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        className={'form-control' + (emailError ? ' error wobble-error' : '')}
                        name="email"
                        required
                        placeholder={t('contacts.form.email', 'Email')}
                        value={email}
                        onChange={event => {
                          // @ts-ignore
                          setEmail(event.target.value);
                          setEmailError('');
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-field col-sm-6 col-md-4">
                    <div className="form-group">
                      <input
                        type="tel"
                        className={'form-control' + (phoneError ? ' error wobble-error' : '')}
                        name="phone"
                        placeholder={t('contacts.form.phone', 'Telefone')}
                        value={phone}
                        onChange={event => {
                          // @ts-ignore
                          setPhone(event.target.value);
                          setPhoneError('');
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="company"
                        placeholder={t('contacts.form.company', 'Empresa')}
                        value={company}
                        onChange={event => {
                          // @ts-ignore
                          setCompany(event.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-field col-sm-12 col-md-4">
                    <div className="form-group">
                      <textarea
                        className={'form-control' + (messageError ? ' error wobble-error' : '')}
                        name="message"
                        placeholder={t('contacts.form.message', 'Mensagem')}
                        value={message}
                        onChange={event => {
                          // @ts-ignore
                          setMessage(event.target.value);
                          setMessageError('');
                        }}
                      />
                    </div>
                  </div>

                  {/* MENSAGENS DE ERRO */}

                  {submitted || hasErrors ? (
                    <div className="col-message col-field col-sm-12" style={{ display: 'block' }}>
                      <div className="form-group">
                        {submitted && (
                          <div className="success-message" style={{ display: 'block' }}>
                            <i className="fa fa-check text-primary" />{' '}
                            {t(
                              'contacts.form.success_message',
                              'Muito obrigada! A sua mensagem foi enviada com sucesso.',
                            )}
                          </div>
                        )}
                        {hasErrors && (
                          <div className="error-message" style={{ display: 'block' }}>
                            {nameError && (
                              <div>
                                <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                {nameError}
                              </div>
                            )}
                            {emailError && (
                              <div>
                                <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                {emailError}
                              </div>
                            )}
                            {phoneError && (
                              <div>
                                <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                {phoneError}
                              </div>
                            )}
                            {messageError && (
                              <div>
                                <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                {messageError}
                              </div>
                            )}
                            {formError && (
                              <div>
                                <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                {formError}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="form-submit text-right">
                  <button type="submit" className="btn btn-shadow-2 wow swing" onClick={onSubmit}>
                    {t('contacts.form.submit', 'Enviar')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacts;
