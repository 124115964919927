import React from 'react';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';
import { SanityImage } from '../types';

interface ServicesProps {
  title: React.ReactNode;
  introText: string;
  services: Array<{
    name: string;
    description: string;
    image: SanityImage;
  }>;
}

const Services = ({ title, introText, services }: ServicesProps) => {
  return (
    <section id="ourServices" className="services section">
      <div id="ourServices-anchor" className="anchor"></div>
      <div className="container">
        <header className="section-header">
          <h2 className="section-title">{title}</h2>
          <p className="intro-text">{introText}</p>
        </header>
        <div className="content">
          <div className="services">
            {services.map((service, i) => (
              <div key={i} className="service wow fadeInUp">
                <img
                  className="serviceIcon"
                  src={service.image.asset.url + '?w=100&h=100&fit=crop'}
                  alt=""
                />
                <h4 className="subtitle">{service.name}</h4>
                <p>{service.description}</p>
              </div>
            ))}
            {Array.from({ length: (3 - (services.length % 3)) % 3 }).map((__, i: number) => (
              <div key={i} className="mockService"></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
