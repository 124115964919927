import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';
import { SanityImage } from '../types';

import yearCircle from '../assets/cleverReal-03.png';

interface PreviousProjectsProps {
  sectionTitle: React.ReactNode;
  projects: Array<{
    year: string;
    name: string;
    description: React.ReactElement;
    image: SanityImage;
  }>;
}

const PreviousProjects = ({ sectionTitle, projects }: PreviousProjectsProps) => {
  const { openLightbox } = useLightbox();

  const sortedProjects = projects
    .map((project, i) => ({
      ...project,
      index: i,
      year: parseInt(project.year),
    }))
    .sort((p1, p2) => p1.year - p2.year);

  const projectLines = [];
  let nextSide = 'left';
  let lastYear = null;
  for (let i = 0; i < sortedProjects.length; i++) {
    const isFirstYearLine = sortedProjects[i].year !== lastYear;
    if (i !== sortedProjects.length - 1 && sortedProjects[i].year === sortedProjects[i + 1].year) {
      projectLines.push([sortedProjects[i], sortedProjects[i + 1], isFirstYearLine]);
      i++;
    } else if (nextSide === 'left') {
      projectLines.push([sortedProjects[i], null, isFirstYearLine]);
      nextSide = 'right';
    } else {
      projectLines.push([null, sortedProjects[i], isFirstYearLine]);
      nextSide = 'left';
    }
    lastYear = sortedProjects[i].year;
  }

  const lightboxImages = sortedProjects.map(project => ({
    src: project.image.asset.url + '?h=900',
    thumbnail: project.image.asset.url + '?w=200',
    width: 1920,
    height: 'auto',
  }));

  const customLightboxCaptions = sortedProjects.map(project => ({
    id: project.index,
    caption: `<div style="text-align: center"><div><strong>${
      project.name
    }</strong></div><div>${ReactDOMServer.renderToString(project.description)}</div></div>`,
  }));

  return (
    <section id="previousProjects" className="previousProjects section">
      <SRLWrapper images={lightboxImages} customCaptions={customLightboxCaptions}></SRLWrapper>
      <div id="previousProjects-anchor" className="anchor"></div>
      <div className="container">
        <header className="section-header">
          <h2 className="section-title">{sectionTitle}</h2>
        </header>
        <div className="section-content">
          {projectLines.map(([p1, p2, isFirstYearLine], i: number) => {
            if (typeof p1 === 'boolean' || typeof p2 === 'boolean') {
              throw new Error('Shut up typescript');
            }
            // @ts-ignore
            const year = (p1 || p2).year;
            return (
              <div key={i} className="project-wrapper">
                <div className="project-row">
                  {p1 !== null ? (
                    <div className="project-container wow fadeInLeft">
                      <div className="mirror-project">
                        <div className="project-info">
                          <h4 className="project-name">{p1.name}</h4>
                          <span className="project-description">{p1.description}</span>
                        </div>
                        <div className="project-image-wrapper">
                          <img
                            className="project-image"
                            src={p1.image.asset.url + '?w=200&h=200&fit=crop'}
                            alt={p1.name}
                            onClick={() => openLightbox(p1.index)}
                          />
                        </div>
                      </div>
                      <div className="side-line"></div>
                    </div>
                  ) : (
                    <div className="project-container">
                      <div className="empty-project"></div>
                    </div>
                  )}
                  <div
                    className={
                      'align-year-items' + (isFirstYearLine ? ' ' : ' project-not-first-year')
                    }
                  >
                    {i !== 0 && <div className="year-line-mobile"></div>}
                    <div className={'align-year-and-image'}>
                      <img className="image" src={yearCircle} alt="" />
                      <div className="year-wrapper">
                        <span className="year">{year}</span>
                      </div>
                    </div>
                    {i + 1 === projectLines.length && (
                      <div className="year-line-mobile year-line-mobile-bottom"></div>
                    )}
                    {i < projectLines.length - 1 && <div className="year-line"></div>}
                  </div>
                  {p1 && p2 && <div className="year-line-mobile year-line-mobile-middle"></div>}
                  {p2 !== null ? (
                    <div className="project-container project-container-second wow fadeInRight">
                      <div className="side-line"></div>
                      <div className="project">
                        <div className="project-image-wrapper">
                          <img
                            className="project-image"
                            src={p2.image.asset.url + '?w=200&h=200&fit=crop'}
                            alt={p2.name}
                            onClick={() => openLightbox(p2.index)}
                          />
                        </div>
                        <div className="project-info">
                          <h4 className="project-name">{p2.name}</h4>
                          <span className="project-description">{p2.description}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="project-container">
                      <div className="empty-project"></div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PreviousProjects;
