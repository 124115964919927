import React from 'react';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';

interface WhoWeAreProps {
  watermark: string;
  sectionTitle: React.ReactNode;
  textIntro: React.ReactNode;
  missionTitle: string;
  missionText: React.ReactNode;
  valuesTitle: string;
  values: Array<string>;
}

const WhoWeAre = ({
  watermark,
  sectionTitle,
  textIntro,
  missionTitle,
  missionText,
  valuesTitle,
  values,
}: WhoWeAreProps) => {
  return (
    <section id="whoWeAre" className="whoWeAre section">
      <div id="whoWeAre-anchor" className="anchor "></div>
      <div className="container">
        <header className="section-header">
          <strong className="fade-title-left">{watermark}</strong>
          <h2 className="section-title">{sectionTitle}</h2>
        </header>
        <div className="section-content wow fadeIn">
          <div className="row-base row">
            <div className="col-base col-sm-6 col-md-4">
              <h3 className="col-about-title text-intro">{textIntro}</h3>
              <div className="col-about-info"></div>
            </div>
            <div className="col-base col-about-spec col-sm-6 col-md-4">
              <h4 className="subtitle">{missionTitle}</h4>
              {missionText}
            </div>
            <div className="col-base col-about-spec col-sm-6 col-md-4">
              <h4 className="subtitle">{valuesTitle}</h4>
              <div className="valuesContainer">
                {values.map((value, i) => (
                  <span key={i} className="value">
                    <i className="fas fa-chevron-right icon"></i>
                    {' ' + value}
                  </span>
                ))}
              </div>
            </div>
            <div className="clearfix visible-sm" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
