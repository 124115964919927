import React from 'react';

import placeholder from '../assets/cleverReal-02.png';

import '../pages/index.scss';
import '../pages/tablet.scss';
import '../pages/mobile.scss';
import { SanityImage } from '../types';

interface TeamProps {
  watermark: string;
  title: string;
  teamText: React.ReactNode;
  teamLeaders: Array<{
    name: string;
    department: string;
    linkedInUrl: string;
    curriculumVitae?: {
      asset: {
        url: string;
      };
    };
    profileImage: SanityImage;
  }>;
  teamWorkers: Array<{
    name: string;
    department: string;
    linkedInUrl: string;
    curriculumVitae?: {
      asset: {
        url: string;
      };
    };
    profileImage: SanityImage;
  }>;
}

const Team = ({ watermark, title, teamText, teamLeaders, teamWorkers }: TeamProps) => {
  return (
    <section id="team" className="section">
      <div id="whoWeAre-anchor" className="anchor"></div>
      <div className="container">
        <header className="section-header">
          <div className="row-base row">
            <strong className="fade-title-left">{watermark}</strong>
            <div className="col-base col-about-spec col-sm-6 col-md-8">
              <h4 className="subtitle">{title}</h4>
              {teamText}
            </div>
          </div>
        </header>
      </div>
      <div className="container team-members-container">
        <div className="content">
          <div className="team-leaders">
            {teamLeaders.map((teamLeader, i) => (
              <div key={i} className="team-contact wow fadeInUp">
                {teamLeader.profileImage ? (
                  <img
                    className="profile-image"
                    src={teamLeader.profileImage.asset.url + '?w=300&h=300&fit=crop'}
                    alt=""
                  />
                ) : (
                  <img className="placeholder" src={placeholder} alt="" />
                )}
                <h4>{teamLeader.name}</h4>
                <span>{teamLeader.department}</span>
                <div className="team-contact-links">
                  {!!teamLeader.curriculumVitae && (
                    <a
                      href={teamLeader.curriculumVitae.asset.url + '?dl'}
                      className="team-contact-cv"
                    >
                      CV
                    </a>
                  )}
                  {!!teamLeader.linkedInUrl && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={teamLeader.linkedInUrl}
                      className="team-contact-linkedin"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  )}
                </div>
              </div>
            ))}
            {Array.from({ length: (3 - (teamLeaders.length % 3)) % 3 }).map((__, i: number) => (
              <div key={i} className="mock-team-contact"></div>
            ))}
          </div>
          <div className="team-workers">
            {teamWorkers.map((teamWorker, i) => (
              <div key={i} className="team-contact wow fadeInUp">
                {teamWorker.profileImage ? (
                  <img
                    className="profile-image"
                    src={teamWorker.profileImage.asset.url + '?w=300&h=300&fit=crop'}
                    alt=""
                  />
                ) : (
                  <img className="placeholder" src={placeholder} alt="" />
                )}
                <h4>{teamWorker.name}</h4>
                <span>{teamWorker.department}</span>
                <div className="team-contact-links">
                  {!!teamWorker.curriculumVitae && (
                    <a
                      href={teamWorker.curriculumVitae.asset.url + '?dl'}
                      className="team-contact-cv"
                    >
                      CV
                    </a>
                  )}
                  {!!teamWorker.linkedInUrl && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={teamWorker.linkedInUrl}
                      className="team-contact-linkedin"
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  )}
                </div>
              </div>
            ))}
            {Array.from({ length: (3 - (teamWorkers.length % 3)) % 3 }).map((__, i: number) => (
              <div key={i} className="mock-team-contact"></div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
